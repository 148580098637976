
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('agri_portal.service') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.component')"
              label-for="component_id"
              >
              <b-form-select
                plain
                v-model="search.component_id"
                id="component_id"
                :options="componentList"
                >
                  <template v-slot:first>
                    <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('document.organization')"
              label-for="org_id"
              >
              <b-form-select
                plain
                v-model="search.org_id"
                id="org_id"
                :options="orgList"
                :disabled="authOrgId > 0"
                >
                  <template v-slot:first>
                    <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('agri_portal.service_name_list')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('agri_portal.service') + ' ' + $t('globalTrans.list')  }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(component_name)="data">
                      <span class="capitalize">{{ getConpName(data.item.component_id) }}</span>
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ getMainStoreData('orgList', data.item.org_id) }}</span>
                    </template>
                    <template v-slot:cell(name)="data">
                      <span class="capitalize">{{ data.item.name }}</span>
                    </template>
                    <template v-slot:cell(name_bn)="data">
                      {{ data.item.name_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-success' : 'badge-danger'">{{ data.item.status ? $t('globalTrans.active') : $t('globalTrans.inactive') }} </span>
                    </template>
                    <template v-slot:cell(is_featured)="data">
                      <span class="badge"> {{ data.item.is_featured ? $t('globalTrans.active') : $t('globalTrans.inactive') }} </span>
                    </template>
                    <template v-slot:cell(is_feature)="data">
                        <b-form-checkbox
                        v-model="data.item.is_featured"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                      >
                    </b-form-checkbox>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>

                    </template>
                     <template #custom-foot="">
                      <b-tr>
                        <b-th colspan="4"></b-th>
                        <b-th width="130px" class="text-center">
                          <button class="btn btn-primary btn-sm" v-on:click="SubmitData()">
                            <i class="fas fa-list"></i>Submit
                          </button>
                        </b-th>
                        <b-th colspan="2"></b-th>
                      </b-tr>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { serviceList, serviceToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Common from '@/mixins/common'

export default {
  mixins: [ModalBaseMasterList, Common],
  components: {
    Form
  },
  data () {
    return {
      search: {
        name: '',
        org_id: 0,
        component_id: 0
      },
      datas: [],
      authOrgId: 0
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? `${this.$t('agri_portal.service')} ${this.$t('globalTrans.entry')}` : `${this.$t('agri_portal.service')} ${this.$t('globalTrans.modify')}`
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('org_pro.component'), class: 'text-left' },
          { label: this.$t('agri_portal.service_name_list'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.featured'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'component_name' },
          { key: 'name_bn' },
          { key: 'status' },
          { key: 'is_feature' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'component_name' },
          { key: 'name' },
          { key: 'status' },
          { key: 'is_feature' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    componentList () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.$store.state.Auth.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
   getConpName (id) {
     const comp = this.componentList.find(item => item.value === id)
     if (typeof comp === 'undefined') {
       return ''
     } else {
       return comp.text
     }
   },
   async SubmitData () {
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((resultSwal) => {
        if (resultSwal.isConfirmed) {
          this.confirmData()
        }
      })
    },
    async confirmData () {
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      result = await RestApi.postData(commonServiceBaseUrl, '/portal/services/update-feature', this.datas)
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadData()
      }
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, serviceToggleStatus, item)
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: '',
        editable: false
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      RestApi.getData(commonServiceBaseUrl, serviceList, params).then(response => {
        if (response.success) {
          this.datas = response.data.data
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
