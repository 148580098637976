<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="formLoad">
          <iq-card>
            <template v-slot:body>
              <div class="container pr-5 pl-5">
              <b-row>
                <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Component" vid="component_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="component_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('org_pro.component') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="service.component_id"
                                  id="component_id"
                                  :options="componentList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6 v-if="service.component_id !== 2">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min:1">
                              <b-form-group
                                  label-cols-sm="12"
                                  class="text-dark"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                                </template>
                                <div style="border: 1px solid gray; padding: 10px;border-radius:3px">
                                  <b-form-checkbox-group v-if="orgList.length"
                                    class="text-dark"
                                    v-model="service.org_id"
                                    :options="orgList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="authOrgId > 0"
                                  ></b-form-checkbox-group>
                                  <p class="m-0 p-0" v-else>--- Please Select Component ---</p>
                                </div>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                         <b-col md=3 v-if="service.component_id === 2">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="service_org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="service.org_id"
                                  :options="orgList"
                                  id="service_org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="authOrgId > 0"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                         </b-col>
                          <b-col md=3 v-if="service.component_id === 2">
                            <ValidationProvider name="Service Name" vid="service_id" rules="required|min:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="service_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('agri_portal.service')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-skeleton v-if="serviceLoad" type="input"></b-skeleton>
                                  <b-form-select v-else
                                  plain
                                  v-model="license.service_id"
                                  :options="serviceList"
                                  id="service_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Service Category" vid="service_category_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="service_category_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('agri_portal.service_category_list')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="service.service_category_id"
                                  :options="portalServiceCategoryList"
                                  id="service_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Service Customer Type" vid="customer_type_id" rules="required|min:1">
                              <b-form-group
                                  label-cols-sm="12"
                                  class="text-dark"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('agri_portal.service_customer_type_list') }} <span class="text-danger">*</span>
                                </template>
                                  <b-form-checkbox-group
                                    class="text-dark"
                                    v-model="service.customer_type_id"
                                    :options="portalServiceCustomerTypeList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-checkbox-group>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                         </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Service Name (En)" vid="name" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="name"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_name')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="name"
                                  data-vv-as="Service Name"
                                  v-model="service.name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Service Name (Bn)" vid="name_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="name_bn"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_name_bn')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="name_bn"
                                  v-model="service.name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=12>
                            <ValidationProvider name="Service Description (En)" vid="description" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                {{$t('agri_portal.description')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                    id="description"
                                    v-model="service.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=12>
                            <ValidationProvider name="Service Description (Bn)" vid="description_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description_bn"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                                <template v-slot:label>
                                  {{$t('agri_portal.description_bn')}} <span class="text-danger">*</span>
                                </template>
                                <vue-editor
                                      id="description_bn"
                                      v-model="service.description_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </vue-editor>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Anual Duration (En)" vid="anual_duration" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="anual_duration"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.anual_duration')}}
                              </template>
                                <b-form-input
                                  id="anual_duration"
                                  v-model="service.anual_duration"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Anual Duration (Bn)" vid="anual_duration_bn" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="anual_duration_bn"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.anual_duration_bn')}}
                              </template>
                                <b-form-input
                                  id="namebn"
                                  v-model="service.anual_duration_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Service Applied" vid="service_applied" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_applied"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_applied')}}
                              </template>
                                <b-form-input
                                  id="service_applied"
                                  v-model="service.applied_service"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Service Received" vid="service_received" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_received"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_received')}}
                              </template>
                                <b-form-input
                                  id="namebn"
                                  v-model="service.received_service"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Target Customer" vid="target_customer" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="target_customer"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.target_customer')}}
                              </template>
                                <b-form-input
                                  id="namebn"
                                  v-model="service.target_customer"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Service Url" vid="service_url" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_url"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_url')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="namebn"
                                  v-model="service.service_url"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Video Url" vid="video_url" rules="">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="video_url"
                                slot-scope="{ valid, errors }"
                                v-bind:star-size="90"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.video_url')}}
                              </template>
                                <b-form-input
                                  id="video_url"
                                  v-model="service.video_url"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12 py-1 text-dark">
                                {{$t('agri_portal.manual_attachment')}}
                              </div>
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-9">
                                    <ValidationProvider name="Manual Attachment" vid="manual_attachment" >
                                      <b-form-group
                                        slot-scope="{ valid, errors }"
                                      >
                                      <b-form-file id="manual_attachment"
                                      v-on:change="onFileChangeManual"
                                      v-model="manual_attachment"
                                      class=" text-dark" plain
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-file>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-3">
                                      <a v-if="service.manual_attachment" :href="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.manual_attachment" class="btn btn-sm btn-success"><i class="fa fa-download m-0" aria-hidden="true"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_apply_btn"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.apply_or_view')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.apply_or_view"
                                  :options="[{ text: $t('agri_portal.apply'), value: 0 },{ text: $t('agri_portal.view'), value: 1 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </b-row>
                        <div class="row">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.service_attachment_list')}}</p>
                          </div>
                          <div class="col-md-9">
                            <div class="row" v-for="(attachemnt, index) in attachment" :key="index">
                              <div class="col-md-5">
                                <ValidationProvider name="Name (En)" :vid="`name_`+index" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label-for="`name_`+index"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('agri_portal.attachment_name')}}
                                  </template>
                                    <b-form-input
                                      :id="`name_`+index"
                                      v-model="attachemnt.name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-5">
                                <ValidationProvider name="Name (Bn)" :vid="`name_bn_`+index" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label-for="`name_bn_`+index"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('agri_portal.attachment_name_bn')}}
                                  </template>
                                    <b-form-input
                                      :id="`name_bn_`+index"
                                      v-model="attachemnt.name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-2">
                                <div style="width:100%;height:100%;display: grid">
                                  <b-button v-if="index === 0" type="button" class="" style="margin: auto" @click="addAttachment" variant="primary"><i class="ri-add-line p-0 m-0"></i></b-button>
                                  <b-button v-if="index !== 0" type="button" class="" style="margin: auto" @click="removeAttachment(index)" variant="danger"><i class="ri-close-fill p-0 m-0"></i></b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.service_fees_list')}}</p>
                          </div>
                          <div class="col-md-9">
                            <div class="row" v-for="(fee, index) in fees" :key="index">
                              <div class="col-md-5">
                                <ValidationProvider name="Name (En)" :vid="`name` + index" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label-for="`name` + index"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('agri_portal.fees_name')}}
                                  </template>
                                    <b-form-input
                                      :id="`name` + index"
                                      v-model="fee.name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-5">
                                <ValidationProvider name="Name (Bn)" :vid="`name_bn` + index" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label-for="`name_bn` + index"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('agri_portal.fees_name_bn')}}
                                  </template>
                                    <b-form-input
                                      :id="`name_bn` + index"
                                      v-model="fee.name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-2">
                                <div style="width:100%;height:100%;display: grid">
                                  <b-button type="button" v-if="index === 0" @click="addFee" class="" style="margin: auto" variant="primary"><i class="ri-add-line p-0 m-0"></i></b-button>
                                  <b-button type="button" v-if="index !== 0" @click="removeFee(index)" class="" style="margin: auto" variant="danger"><i class="ri-close-fill p-0 m-0"></i></b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.form_attachment')}}</p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-5">
                                <ValidationProvider name="Form Attachment" vid="form_attachment" >
                                  <b-form-group
                                    label-for="form_attachment"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <b-form-file id="form_attachment"
                                  v-on:change="onFileChange"
                                  v-model="form_attachment"
                                  class=" text-dark" plain
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-5">
                                <a v-if="service.form_attachment" :href="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.form_attachment" class="btn btn-sm btn-success"><i class="fa fa-download" aria-hidden="true"></i>{{ $t('agri_portal.download') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.service_process')}}</p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-5">
                                <ValidationProvider name="Service Process" vid="process" >
                                  <b-form-group
                                    label-for="process"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <b-form-file id="process"
                                  v-on:change="onFileChangePro"
                                  v-model="process"
                                  class=" text-dark" plain
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-5">
                                <img v-if="service.process" width="100%" height="80px" :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.process"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.service_image')}}</p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-5">
                                <ValidationProvider name="Service Image" vid="image" >
                                  <b-form-group
                                    label-for="image"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <b-form-file id="image"
                                  v-on:change="onFileChangeImg"
                                  v-model="image"
                                  class=" text-dark" plain
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-5">
                                <img v-if="service.image" width="100%" height="80px" :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.image"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <b-col class="col-md-12 mb-3">
                            <h4>
                              Permissions: <hr class="p-0 m-0">
                            </h4>
                          </b-col>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_apply_btn"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.apply_button')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_apply_btn"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_attachment_form"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.form_attachment')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_attachment_form"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_service_applied"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_applied')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_service_applied"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_service_received"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_received')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_service_received"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_target_customer"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.target_customer')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_target_customer"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_service_time"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_time')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_service_time"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_attachment"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_attachment_list')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_attachment"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_fees"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_fees_list')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_fees"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_service_process"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_process')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_service_process"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_rating"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.rating')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_rating"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="pr_image"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.service_image')}}
                              </template>
                                <b-form-radio-group
                                  v-model="service.pr_image"
                                  :options="[{ text: 'Yes', value: 1 },{ text: 'No', value: 0 }]"
                                ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                </b-col>
              </b-row>
              </div>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl, licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { serviceStore, serviceUpdate } from '../../api/routes'
import { orgservice } from '../../../../license-registration-service/configuration/api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      this.edit = true
      const tmp = this.getServiceServiceData()
      this.service = tmp
      this.attachment = tmp.attachment
      this.fees = tmp.fees
      const customerType = tmp.customer_types.map(item => {
        return item.id
      })
      this.service.customer_type_id = customerType
      const orgList = tmp.org_list.map(item => {
        return item.id
      })
      if (tmp.component_id === 2) {
        this.service.org_id = orgList[0]
      } else {
        this.service.org_id = orgList
      }
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formLoad: false,
      commonServiceBaseUrl: commonServiceBaseUrl,
      service: {
        component_id: '0',
        org_id: [],
        name: '',
        name_bn: '',
        service_category_id: 0,
        customer_type_id: [],
        description: '',
        description_bn: '',
        anual_duration: '',
        anual_duration_bn: '',
        process: '',
        image: '',
        form_attachment: '',
        manual_attachment: '',
        pr_apply_btn: 0,
        pr_attachment_form: 0,
        pr_attachment: 0,
        applied_service: 0,
        received_service: 0,
        apply_or_view: 0,
        pr_service_applied: 0,
        pr_service_received: 0,
        pr_target_customer: 0,
        pr_service_time: 0,
        pr_fees: 0,
        pr_service_process: 0,
        pr_rating: 0,
        pr_image: 0,
        service_url: '',
        video_url: ''
      },
      attachment: [
          {
            name: '',
            name_bn: ''
          }
        ],
      fees: [
        {
          name: '',
          name_bn: ''
        }
      ],
      manual_attachment: [],
      form_attachment: [],
      process: [],
      image: [],
      orgList: [],
      license: {
        service_id: 0,
        step_id: ''
      },
      serviceList: [],
      serviceLoad: false,
      edit: false,
      authOrgId: 0
    }
  },
  watch: {
    'service.component_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.edit === false) {
          this.service.org_id = []
        }
        this.orgList = this.getOrgList(newVal)
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.authOrgId = this.$store.state.Auth.authUser.org_id
          this.$set(this.service.org_id, 0, this.authOrgId)
        }
      }
    },
    'service.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if ((this.service.component_id === 2 && newVal.length > 0) || (this.service.component_id === 2 && newVal > 0)) {
          this.serviceLoad = true
          RestApi.getData(licenseRegistrationServiceBaseUrl, orgservice + '/' + newVal).then(response => {
            if (response.success) {
              this.license.step_id = response.data.step.step_id
              const services = response.data.service
              this.serviceList = services.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.id, text: obj.service_name_bn, text_en: obj.service_name, text_bn: obj.service_name_bn }
                } else {
                  return { value: obj.id, text: obj.service_name, text_en: obj.service_name, text_bn: obj.service_name_bn }
                }
              })
            }
            this.serviceLoad = false
          })
        }
      }
    },
    'license.service_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal.length > 0 || newVal > 0) {
          const serviceObj = this.serviceList.find(item => item.value === newVal)
          this.service.name = serviceObj.text_en
          this.service.name_bn = serviceObj.text_bn
          this.service.service_url = '/lrcpn/application-form?step_id=' + this.license.step_id + '&org_id=' + this.service.org_id + '&service_id=' + newVal
        }
      }
    }
  },
  computed: {
    componentList () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    portalServiceCategoryList: function () {
      return this.$store.state.commonObj.portalServiceCategoryList.filter(item => item.status === 1)
    },
    portalServiceCustomerTypeList: function () {
      return this.$store.state.commonObj.portalServiceCustomerTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    onFileChangeManual (e) {
      this.manual_attachment = e.target.files[0]
    },
    onFileChange (e) {
      this.form_attachment = e.target.files[0]
    },
    onFileChangePro (e) {
      this.process = e.target.files[0]
    },
    onFileChangeImg (e) {
      this.image = e.target.files[0]
    },
    addAttachment () {
      const tmpAtt = {
        name: '',
        name_bn: ''
      }
      this.attachment.push(tmpAtt)
    },
    removeAttachment (index) {
      this.attachment.splice(index, 1)
    },
    addFee () {
      const tmpFee = {
        name: '',
        name_bn: ''
      }
      this.fees.push(tmpFee)
    },
    removeFee (index) {
      this.fees.splice(index, 1)
    },
    getServiceServiceData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      try {
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.formLoad = true

        const formData = new FormData()
        Object.keys(this.service).map(key => {
            if (key === 'image') {
              formData.append(key, this.image)
            }
            if (key === 'process') {
              formData.append(key, this.process)
            }
            if (key === 'manual_attachment') {
              formData.append(key, this.manual_attachment)
            }
            if (key === 'form_attachment') {
              formData.append(key, this.form_attachment)
            } else {
              formData.append(key, this.service[key])
            }
        })
        formData.append('org_id', JSON.stringify(this.service.org_id))
        formData.append('customer_type_id', JSON.stringify(this.service.customer_type_id))
        formData.append('attachment', JSON.stringify(this.attachment))
        formData.append('fees', JSON.stringify(this.fees))

        if (this.id) {
          formData.append('_method', 'PUT')
          result = await RestApi.postData(commonServiceBaseUrl, `${serviceUpdate}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(commonServiceBaseUrl, serviceStore, formData)
        }
        this.formLoad = false
        loadingState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$toast.success({
            title: 'Success',
            message: this.id ? 'Data updated successfully' : 'Data save successfully',
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
            // this.$toast.error({
            //   title: 'Error',
            //   message: 'Operation failed! Please, try again.'
            // })
        }
      } catch (error) {
          this.$toast.error({
                title: 'Error',
                message: 'something went wrong! Please, try again.'
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    },
    getOrgList (compId) {
      const orgComponentList = this.$store.state.orgComponentList.filter(comp => comp.component_id === compId)
      return orgComponentList.map(item => {
        if (typeof item !== 'undefined') {
          const org = this.$store.state.orgList.find(org => org.value === item.org_id)
          return (this.$i18n.locale === 'bn') ? Object.assign({}, org, { text: org.abbreviation_bn }) : Object.assign({}, org, { text: org.abbreviation })
        }
      })
    }
  }
}
</script>
