// Country
const serviceCategory = '/portal/service-category'
export const serviceCategoryList = serviceCategory + '/list'
export const serviceCategoryStore = serviceCategory + '/store'
export const serviceCategoryUpdate = serviceCategory + '/update'
export const serviceCategoryToggleStatus = serviceCategory + '/toggle-status'
export const serviceCategoryDestroy = serviceCategory + '/destroy'

const serviceCustomerType = '/portal/service-customer-type'
export const serviceCustomerTypeList = serviceCustomerType + '/list'
export const serviceCustomerTypeStore = serviceCustomerType + '/store'
export const serviceCustomerTypeUpdate = serviceCustomerType + '/update'
export const serviceCustomerTypeToggleStatus = serviceCustomerType + '/toggle-status'
export const serviceCustomerTypeDestroy = serviceCustomerType + '/destroy'

const service = '/portal/services'
export const serviceList = service + '/list'
export const serviceStore = service + '/store'
export const serviceUpdate = service + '/update'
export const serviceToggleStatus = service + '/toggle-status'
export const serviceFeature = service + '/toggle-status'
export const serviceDestroy = service + '/destroy'
export const serviceFeatureUpdate = service + '/update-feature'

const News = '/portal/News'
export const NewsList = News + '/list'
export const NewsStore = News + '/store'
export const NewsUpdate = News + '/update'
export const NewsToggleStatus = News + '/toggle-status'

const Faq = '/portal/Faq'
export const FaqList = Faq + '/list'
export const FaqStore = Faq + '/store'
export const FaqUpdate = Faq + '/update'
export const FaqToggleStatus = Faq + '/toggle-status'

const Notice = '/portal/notice'
export const NoticeList = Notice + '/list'
export const NoticeStore = Notice + '/store'
export const NoticeUpdate = Notice + '/update'
export const NoticeToggleStatus = Notice + '/toggle-status'

const header = '/portal/header'
export const headerList = header + '/list'
export const headerStore = header + '/store'
export const headerUpdate = header + '/update'
export const headerToggleStatus = header + '/toggle-status'
export const headerDestroy = header + '/destroy'

const SocialMedia = '/portal/social-media'
export const SocialMediaList = SocialMedia + '/list'
export const SocialMediaStore = SocialMedia + '/store'
export const SocialMediaUpdate = SocialMedia + '/update'
export const SocialMediaToggleStatus = SocialMedia + '/toggle-status'

// About Service portal apis
const About = '/portal/about-service-portal'
export const aboutPortalListApi = About + '/list'
export const aboutPortalStoreApi = About + '/store'
export const aboutPortalUpdateApi = About + '/update'
export const aboutPortalToggleStatusApi = About + '/toggle-status'
